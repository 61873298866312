const vanillaExcuses = [
    'Waylaid by rampaging rhinos',
    'Too busy scheming',
    'Shiny object in vicinity',
    'Cartesian demon',
    'Five-hour shower',
    'Dog ate my homework',
    'Meddling kids',
    'Foiled again',
    'Entangled by ribbons',
    'Taking ring to Mordor',
    'Whale took my leg',
    'Broke my eggshell',
    'Gone fishing',
    'Fat Bear Year',
    'Yetzer hara',
    'Wheat Thins overdose',
    'Systemic factors',
    'Went gremlin mode',
]
const censorableExcuses = [
    'You were fucking me at the time',
    'Personally, I blame the Jews',
]

export default function getRandomExcuse() {
    const settings = JSON.parse(window.localStorage.getItem('settings') || '{}')
    const excuses = settings.censored ? vanillaExcuses : vanillaExcuses.concat(censorableExcuses)
    return excuses[Math.floor(Math.random() * excuses.length)]
}