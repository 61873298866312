import { useEffect, useState } from 'react'

import _ from 'lodash'
import {
    Accordion,
    Center,
    List,
    ListItem,
    ListIcon,
    StackDivider,
    Text,
    VStack,
} from '@chakra-ui/react'

import AccordionItemBoilerplate from './helpers/AccordionItemBoilerplate'
import dayjs from '../dayjs'
import { baseUrl } from '../env'
import { iconAdders, getStyleForIcon } from '../util/historyIcons'

import type { IsoDate } from '../types/branded'
import type { HistoryData } from '../types/other'

function History() {
    const [history, setHistory] = useState<[IsoDate, HistoryData.PossibleEntry[]][]>([])
    const [oldHistoryState, setOldHistoryState] = useState<'hidden'|'loading'|'visible'>('hidden')

    const getHistory = async ({ lastSevenOnly } : { lastSevenOnly: boolean } = { lastSevenOnly: true }) => {
        const response = await fetch(`${baseUrl}/history${lastSevenOnly ? '?limit=7' : ''}`)
        const data = await response.json()
        setHistory(data)
    }
    useEffect(() => { getHistory() }, [])

    const mapDateEntryPair = ([date, entries]: [IsoDate, HistoryData.PossibleEntry[]]) => (
        <div key={date}>
            <Center py={3}>
                <Text
                    color={'green.500'}
                    textTransform={'uppercase'}
                    fontWeight={800}
                    fontSize={'sm'}
                    letterSpacing={1.1}
                >
                    {dayjs(date).format('dddd, MMMM D')}
                </Text>
            </Center>
            <StackDivider borderColor='blackAlpha.900' />
            <Center py={3}>
                <List>
                    {
                        entries
                        // @ts-ignore
                        .filter(entry => !(entry.isReminder && entry.resolutionState === 'failed'))
                        // Ignore punishment delivery entries
                        // @ts-ignore
                        .filter(entry => !(entry.punishmentQuantity && entry.punishmentQuantity < 0))
                        .reverse()
                        .map(entry => {
                            const {
                                resolutionState,
                                consequenceStates
                            } = entry

                            let state = resolutionState
                            // @ts-ignore
                            if (entry.isReminder && ['completedLate', 'partialGrace'].includes(resolutionState)) {
                                state = 'completed'
                            }

                            const icons = [] as { icon: React.FC, color: string }[]
                            if (resolutionState in iconAdders) {
                                // @ts-ignore
                                const quantity = entry.rewardQuantity || entry.punishmentQuantity
                                iconAdders[state](icons, quantity, consequenceStates)
                            } else {
                                console.error(`Unknown resolution state: ${resolutionState}`, entry)
                            }
                            
                            let taskName: string|undefined
                            if (resolutionState !== 'reward' && resolutionState !== 'punishment') {
                                taskName = entry.taskName
                                if (taskName.startsWith('Bedtime')) {
                                    taskName = 'Bedtime'
                                } else if (taskName.startsWith('Pre-bed')) {
                                    taskName = 'Pre-bed'
                                }
                            }

                            let description = ''
                            if (resolutionState === 'reward' || resolutionState === 'punishment') {
                                // @ts-ignore
                                description = `For ${entry.rewardComment || entry.punishmentComment}.`
                            } else if (entry.isReminder) {
                                description = `Reminder: ${taskName!}`
                            } else if (resolutionState === 'retcon') {
                                description = taskName! + ' [Retcon]'
                            } else if (resolutionState === 'partialGrace') {
                                description = taskName! + ' [Partial grace]'
                            } else {
                                description = taskName!
                            }

                            const id = resolutionState === 'reward' ? entry.rewardId :
                                resolutionState === 'punishment' ? entry.punishmentId :
                                entry.taskOccurrenceId
                            return (
                                <ListItem key={id}>
                                    {icons.map(({ icon, color }, i, pairs) => (
                                        <ListIcon key={id + i} as={icon} color={color} style={getStyleForIcon(i, pairs.length)} />))}
                                    {description}
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Center>
        </div>
    )

    if (!history.length) {
        return (
            <Center>You're trouble.</Center>
        )
    }

    const [recentHistory, ancientHistory] = _.partition(history, ([date]) => dayjs(date).isAfter(dayjs().subtract(7, 'day')))

    return (
        <VStack align='stretch'>
            {
                recentHistory.map(mapDateEntryPair)
            }
            <Accordion allowToggle={true} onChange={async () => {
                setOldHistoryState('loading')
                await getHistory({ lastSevenOnly: false })
                setOldHistoryState('visible')
            }}>
                <AccordionItemBoilerplate
                    buttonText={
                    oldHistoryState === 'hidden' ?
                        'Show older history' :
                        oldHistoryState === 'loading' ?
                            'Loading older history...' :
                            'Hide older history'
                    }
                    panelContent={
                        <VStack align='stretch'>
                            {
                                ancientHistory.map(mapDateEntryPair)
                            }
                        </VStack>
                    }
                />
            </Accordion>
        </VStack>
    )
}

export default History