import { useEffect, useState } from 'react'
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'

import { baseUrl, IS_DEV } from '../env'
import Admin from './Admin'
import Checklist from './Checklist'
import History from './History'
import Login from './Login'
import Orders from './Orders'
import PunishmentStats from './PunishmentStats'
import ReminderForm from './ReminderForm'
import Supertasks from './Supertasks'
import TaskCardStack from './TaskCardStack'

function App() {
  const [settings, setSettings] = useState({} as Record<string, any>)
  useEffect(() => {
    fetch(`${baseUrl}/settings`)
    .then(response => response.json())
    .then(data => {
      setSettings(data)
      window.localStorage.setItem('settings', JSON.stringify(data))
    })
  }, [])
  const [tabIndex, setTabIndex] = useState(Number(window.localStorage.getItem('tabIndex')))
  const [cookie, setCookie] = useState(document.cookie)
  const [showAdmin, setShowAdmin] = useState(
    window.localStorage.getItem('hideAdmin') !== 'true')

  const cookies = cookie.split(';').map(c => c.trim())
  const hasAuthToken = cookies.some(c => c.startsWith('token='))
  if (!hasAuthToken && !IS_DEV) {
    return <div className="App">
      <Login
        setCookie={setCookie}
      />
    </div>
  }

  const showCensorable = localStorage.getItem('adminView') === 'true' || 
    !(settings?.censored)

  // The Box and w="max-content" allow the tabs
  // to scroll when they overflow page width,
  // but are otherwise mysterious to me
  return (
      <div className="App">
        <Tabs
          isLazy={true}
          index={tabIndex}
          onChange={(index) => {
            setTabIndex(index)
            window.localStorage.setItem('tabIndex', index.toString())
          }}
        >
          <Box overflow="auto">
            <TabList w="max-content">
              <Tab>Tasks</Tab>
              <Tab>Supertasks</Tab>
              <Tab>Checklists</Tab>
              <Tab>Reminders</Tab>
              <Tab>History</Tab>
              {showCensorable ? <Tab>Punishments</Tab> : null }
              {showCensorable ? <Tab>Orders</Tab> : null}
              {showAdmin ? <Tab>Admin</Tab> : null}
            </TabList>
          </Box>
          <TabPanels>
            <TabPanel>
              <TaskCardStack />
            </TabPanel>
            <TabPanel>
              <Supertasks />
            </TabPanel>
            <TabPanel>
              <Checklist />
            </TabPanel>
            <TabPanel>
              <ReminderForm />
            </TabPanel>
            <TabPanel>
              <History />
            </TabPanel>
            { !showCensorable ? null :
              <TabPanel>
                <PunishmentStats />
              </TabPanel>
            }
            { !showCensorable ? null :
              <TabPanel>
                <Orders />
              </TabPanel>
            }
            { showAdmin ?
              <TabPanel>
                <Admin
                  setCookie={setCookie}
                  setShowAdmin={setShowAdmin}
                />
              </TabPanel> :
              null
            }
          </TabPanels>
        </Tabs>
      </div>
  )
}

export default App
