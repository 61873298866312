import _ from 'lodash'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
    Button,
    Center,
    Text,
    VStack
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import orderTaskOccurrences from '../orderTaskOccurrences'
import ReminderAccordion from './ReminderAccordion'
import TagFilterBar, { tagNames } from './TagFilterBar'
import TaskOccurrenceCard from './TaskOccurrenceCard'

import type { IsoDate } from '../types/branded'
import { StateUser } from '../types/utility'
import type { TaskOccurrenceWithHistory } from '../types/models'

const fetchOccurrences = async (
    handleOccurrences: StateUser<TaskOccurrenceWithHistory[]>,
    adminView: boolean
) => {
    // Extended query fetches all failed for previous day and all future
    const query = adminView ? '?extended=true' : ''
    const response = await fetch(`${baseUrl}/tasks/occurrences${query}`)
    const data = await response.json()
    handleOccurrences(data)
}

function TaskCardStack() {
    const [occurrences, setOccurrences] = useState<TaskOccurrenceWithHistory[]>([])
    const [activeTags, setActiveTags] = useState(tagNames)
    // If previous date is current logical day, show it, otherwise hide it (for admin mode)
    const startingHiddenDates = dayjs().hour() > 4 ?
        [dayjs().subtract(1, 'day').format('YYYY-MM-DD')] : []
    const [hiddenDates, setHiddenDates] = useState<IsoDate[]>(startingHiddenDates)
    const adminView = window.localStorage.getItem('adminView') === 'true'
    const updateOccurrences = () => { fetchOccurrences(setOccurrences, adminView) }

    useEffect(updateOccurrences, [adminView])

    // Stored as "[['2023-11-17', [uuid1, uuid2]], ['2023-11-18', []]]"
    const hiddenOccurrences = localStorage.getItem('hiddenOccurrences')
    const hiddenOccurrencesArray = JSON.parse(hiddenOccurrences || '[]') as [IsoDate, string[]][]
    const yesterdayDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    const currentDate = dayjs().format('YYYY-MM-DD')
    const relevantDatesArray = hiddenOccurrencesArray
    .filter(([date]) => date >= yesterdayDate)
    .sort((a, b) => a[0] > b[0] ? 1 : -1)
    if (!relevantDatesArray.length) {
        relevantDatesArray.push([yesterdayDate, []], [currentDate, []])
    } else if (relevantDatesArray.length === 1 && relevantDatesArray[0][0] === yesterdayDate) {
        relevantDatesArray.push([currentDate, []])
    } else if (relevantDatesArray.length === 1 && relevantDatesArray[0][0] === currentDate) {
        relevantDatesArray.unshift([yesterdayDate, []])
    }
    const hiddenOccurrenceIds = relevantDatesArray.flatMap(([_, ids]) => ids)
    localStorage.setItem('hiddenOccurrences', JSON.stringify(relevantDatesArray))
    const showHidden = () => {
        localStorage.setItem('hiddenOccurrences', '[]')
        updateOccurrences()
    }
    const ShowHiddenButton = () => !hiddenOccurrenceIds.length ? null :
        <Button size='sm' onClick={showHidden}>Show hidden</Button>

    const hasIncentive = (o: TaskOccurrenceWithHistory) => o.history.hasRewardIncentive ||
        o.history.hasPunishmentIncentive
    const occurrencesByDate = _(occurrences)
    .filter(o => !o.completedAt || (adminView && hasIncentive(o)))
    .filter(o => o.task.isReminder || activeTags.includes(o.task.tag || 'Other'))
    .filter(o => !hiddenOccurrenceIds.includes(o.id))
    .groupBy('date')
    .mapValues(orderTaskOccurrences)
    .mapValues(occurrences => _.partition(occurrences, o => o.task.isReminder))
    .toPairs()
    .orderBy('date')
    .value() as [IsoDate, [TaskOccurrenceWithHistory[], TaskOccurrenceWithHistory[]]][]

    const SectionHeader = ({ text, big } : { text: string, big?: boolean }) => (
        <Center py={3}>
            <Text
                color={'green.500'}
                textTransform={big ? 'uppercase' : 'none'}
                fontWeight={800}
                fontSize={big ? 'sm' : 'xs'}
                letterSpacing={1.1}
            >
                {text}
            </Text>
        </Center>
    )

    const HideDateButton = ({ date } : { date: IsoDate }) => {
        const handleHideDate = () => {
            if (hiddenDates.includes(date)) {
                setHiddenDates(hiddenDates.filter(d => d !== date))
            } else {
                setHiddenDates([...hiddenDates, date])
            }   
        }
        
        return <Center>
            <Button size='xs' onClick={handleHideDate}>
                {hiddenDates.includes(date) ? 'Show' : 'Hide'}
            </Button>
        </Center>
    }

    const ReminderSection = ({ reminders } : { reminders: TaskOccurrenceWithHistory[] }) =>
    !reminders.length ? null :
    <div>
        <SectionHeader text={'Reminders'} />
        <ReminderAccordion
            reminders={reminders}
            setOccurrences={setOccurrences}
            updateOccurrences={updateOccurrences}
        />
        <SectionHeader text={'Tasks'} />
    </div>

    const OccurrenceSection = ({ occurrences } : { occurrences: TaskOccurrenceWithHistory[] }) =>
    <div>
        {occurrences.map(occurrence =>
            <TaskOccurrenceCard
                key={occurrence.id}
                occurrence={occurrence}
                setOccurrences={setOccurrences}
                updateOccurrences={updateOccurrences}
            />
        )}
    </div>

    return !occurrences.length ?
        <Center>{"You're trouble."}</Center> :
        <VStack>
            <TagFilterBar activeTags={activeTags} setActiveTags={setActiveTags}/>
            <ShowHiddenButton />
            {occurrencesByDate.map(([date, [reminders, normalOccurrences]]) =>
                <div key={date}>
                <SectionHeader text={dayjs(date).format('dddd, MMMM D')} big={true} />
                <HideDateButton date={date} />
                { hiddenDates.includes(date) ? null : 
                    <div>
                        <ReminderSection reminders={reminders} />
                        <OccurrenceSection occurrences={normalOccurrences} />
                    </div>
                }
                </div>
            )}
        </VStack>
}

export default TaskCardStack