import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react'

import TaskOccurrenceCard from './TaskOccurrenceCard'

import { StateUser } from '../types/utility'
import type { TaskOccurrenceWithHistory } from '../types/models'

const ReminderAccordion = ({ reminders, setOccurrences, updateOccurrences }:
    {
        reminders: TaskOccurrenceWithHistory[],
        setOccurrences: StateUser<TaskOccurrenceWithHistory[]>,
        updateOccurrences: () => void
    }) => {
    return (
        <Accordion allowToggle={true}>
            {reminders.map(reminder =>
                <AccordionItem key={reminder.id}>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            {reminder.task.name}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <TaskOccurrenceCard
                            occurrence={reminder}
                            setOccurrences={setOccurrences}
                            updateOccurrences={updateOccurrences}
                        />
                    </AccordionPanel>
                </AccordionItem>
            )}
        </Accordion>
    )
}

export default ReminderAccordion