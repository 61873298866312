import { Button } from '@chakra-ui/react'

import { baseUrl } from '../env'

import { StateUser } from '../types/utility'

export default function Login({ setCookie }: { setCookie: StateUser<string> }) {
    const tryLogin = async function(password: string) {
        await fetch(`${baseUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password })
        })
        if (document.cookie !== '') {
            setCookie(document.cookie)
        }
    }

    return (
        <div className='loginContainer' style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <input type='password' id='password' style={{ border: 'black solid 2px' }}/>
            <Button
                onClick={() => tryLogin((document.getElementById('password') as HTMLInputElement).value)}
                style={{ marginLeft: '5px' }}
            >
                Log in
            </Button>
        </div>
    )
}