import {
    CardBody,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'

import dayjs from '../dayjs'

import { IsoTime } from '../types/branded'
import { StateUser } from '../types/utility'

import 'react-datepicker/dist/react-datepicker.css'

function ReminderFormDatetime({ notificationTime, setNotificationTime, deadlineTime, setDeadlineTime }:
    { notificationTime: IsoTime, setNotificationTime: StateUser<IsoTime> , deadlineTime: IsoTime, setDeadlineTime: StateUser<IsoTime> }
) {
    return (
      <CardBody>
        <b>Reminder time:</b><br />
        <DatePicker
            selected={new Date(notificationTime)}
            onChange={(date) => {
                setNotificationTime(date!.toISOString() as IsoTime)
                setDeadlineTime(dayjs(date!).add(1, 'hour').toISOString())
            }}
            dateFormat="MMMM d, yyyy h:mm aa"
            showTimeInput
            inline
        />
        <br />
        <b>Deadline:</b> one hour after reminder
      </CardBody>
    )
}

export default ReminderFormDatetime