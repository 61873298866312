import { Box, UnorderedList, ListItem } from "@chakra-ui/react"

function Orders() {
    const orders = [
        'No unnecessary apologies.',
        'No self-deprecation.',
        'No more than one drink per day without Sam\'s permission.',
        'When allowed to drink more, no mixed drinks unless you know the potency and volume.',
        'No using Tumblr without Sam\'s permission.',
        'No reading Gaza discourse unless you absolutely must.',
    ]

    return (
        <Box>
            A partial list of orders, which I'll add to and eventually make definitive.
            <UnorderedList>
                {orders.map((order) => (
                    <ListItem key={order}>{order}</ListItem>
                ))}
            </UnorderedList>
        </Box>
    )
}

export default Orders
