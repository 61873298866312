import _ from 'lodash'

import { TaskOccurrence } from './types/models'

const taskOrderTiebreakers = {
    'Levothyroxine': -5,
    'Modafinil': -4,
    'Allergy meds': -3,
    'Bullet journaling (morning)': -2,

    'Dinner': -1,

    'Check stove': 2,
    'Bullet journaling (evening)': 3
} as Record<string, number>

export function getTaskOrderTiebreakers(occurrence: TaskOccurrence): number {
    const name = occurrence.task.name
    if (name.startsWith('Pre-bed')) {
        return 1
    }
    if (name.startsWith('Bedtime')) {
        return 10
    }

    return taskOrderTiebreakers[name] || 0
}

// Rank one-off tasks first, then non-daily tasks (except any that are every weekday)
export function orderSpecialTasksFirst(occurrence: TaskOccurrence): number {
    const { task: { isRecurring, isReminder, schedule, name } } = occurrence
    if (isReminder) {
        return -100
    }
    if (name === 'Levothyroxine') {
        return -3
    }
    if (!isRecurring || !schedule) {
        return -2
    }
    if (schedule.length < 5) {
        // These are effectively daily, but have no schedule
        if (name === 'Breakfast' || name === 'Lunch') {
            return 0
        }
        // Handles the case of equivalent daily tasks with different deadlines,
        // like "Foo (M)", "Foo (T/W/Th)", "Foo (F)"
        const daySuffixes = ['M', 'T', 'W', 'Th', 'F', 'Sat', 'Sun']
        for (const suffix of daySuffixes) {
            if (name.endsWith(suffix + ')')) {
                return 0
            }
        }

        return -1
    }

    return 0
}

export function orderTaskOccurrences(occurrences: TaskOccurrence[]): TaskOccurrence[] {
    return _.orderBy(
        occurrences,
        [
            o => o.failedAt !== null, // Incomplete past-deadline tasks first
            orderSpecialTasksFirst,
            'deadline',
            // Manually rank tasks with the same deadline
            getTaskOrderTiebreakers
        ],
        ['desc', 'asc', 'asc', 'asc', 'asc']
    )
}

export default orderTaskOccurrences
