import {
    HStack,
    Icon
} from '@chakra-ui/react'

import { getOccurrenceHistoryRowIcon } from '../util/historyIcons'

import type { TaskOccurrenceHistory } from '../types/models'

const OccurrenceHistoryRow = ({ occurrenceHistory } : { occurrenceHistory : TaskOccurrenceHistory }) => 
    <HStack style={{ backgroundColor: 'white' }}>
        {occurrenceHistory.recentHistory.map((entry) => {
            const [ icon, color ] = getOccurrenceHistoryRowIcon(
                entry.resolutionState,
                occurrenceHistory.hasRewardIncentive,
                occurrenceHistory.hasPunishmentIncentive
            )
            return <Icon key={entry.id} as={icon as typeof Icon} color={color as string} />
        })}
    </HStack>

export default OccurrenceHistoryRow