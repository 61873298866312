import {
  useEffect,
  useState
} from 'react'
import {
  Button,
  Center,
  VStack,
} from '@chakra-ui/react'

import AdminDelayForm from './AdminDelayForm'
import { baseUrl, IS_DEV } from '../env'
import dayjs from '../dayjs'
import Login from './Login'

import { IsoTime } from '../types/branded'
import { StateUser } from '../types/utility'

import 'react-datepicker/dist/react-datepicker.css'
import AdminTaskForm from './AdminTaskForm'
import AdminSuperTaskForm from './AdminSupertaskForm'

export default function Admin({ setCookie, setShowAdmin }: {
    setCookie: StateUser<string>,
    setShowAdmin: StateUser<boolean>
}) {
    const [isAdminViewOn, setIsAdminViewOn] = useState(localStorage.getItem('adminView') === 'true')
    const [lastRequestTime, setLastRequestTime] = useState<IsoTime|null>(null)

    useEffect(() => {
      const fn = async () => {
        if (!document.cookie.includes('admin_token=')) return
        const result = await fetch(`${baseUrl}/admin/lastRequestTime`)
        const data = await result.json()
        setLastRequestTime(data.lastRequestTime)
      }
      fn()
    }, [])

    return (
    !document.cookie.includes('admin_token=') && !IS_DEV ?
    <Center>
      <VStack>
        <Login
          setCookie={setCookie}
        />
        <Button
          onClick={() => {
            setShowAdmin(false)
            window.localStorage.setItem('hideAdmin', 'true')
          }}
        >
          Hide this tab
        </Button>
      </VStack>
    </Center> :
    <Center>
      <VStack>
      <Button
        onClick={() => {
          localStorage.setItem('adminView', isAdminViewOn ? 'false' : 'true')
          setIsAdminViewOn(!isAdminViewOn)
        }}
      >
        Turn admin view { isAdminViewOn ? 'off' : 'on' }
      </Button>
        <div>Last request time: { lastRequestTime ?
          dayjs(lastRequestTime).format('h:mm A z [on] dddd') :
          'No data'
        }</div>
        <AdminDelayForm />
        <AdminTaskForm />
        <AdminSuperTaskForm />
      </VStack>
    </Center>
    )
}