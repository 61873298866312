import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import UTC from 'dayjs/plugin/utc'

import type { IsoDate, IsoTime } from './types/branded'

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(minMax)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(UTC)

declare module 'dayjs' {
    interface Dayjs {
        format(formatString: 'YYYY-MM-DD'): IsoDate
        toISOString(): IsoTime
    }

    interface DayjsStatic {
        (config?: ConfigType): Dayjs
    }
}

export default dayjs