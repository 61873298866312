import { useState, useEffect } from 'react'

import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Center,
    Heading,
    Stack,
    Text,
    VStack
} from '@chakra-ui/react'

import { baseUrl } from '../env'

import type { Task, TaskOccurrence } from '../types/models'
import type { SupertaskId, TaskId } from '../types/branded'

type Supertask = {
    id: SupertaskId
    name: string
    description: string
    isRecurring: boolean
    subtasks: { id: TaskId, name: string }[]
    createdAt: string
    hydratedSubtasks: {
        task: Task
        taskOccurrence?: TaskOccurrence
    }[]
}

const styles = {
    pending: {
      dayColor: 'green.500',
      bg: 'white',
      descriptionColor: 'gray.500'
    },
    confirmed: {
        dayColor: 'black',
        bg: 'green.500',
        descriptionColor: 'black'
    }
}

export default function Supertasks() {
    const [supertasks, setSupertasks] = useState([] as Supertask[])

    useEffect(() => {
        async function fetchSupertasks() {
            const res = await fetch(`${baseUrl}/supertasks`)
            const data = await res.json()
            setSupertasks(data)
        }
        fetchSupertasks()
    }, [])

    const supertaskCards = supertasks.map(supertask => (
        <Center py={3}>
        <Box
            maxW={'445px'}
            w={'full'}
            bg={styles.pending.bg}
            boxShadow={'2xl'}
            rounded={'md'}
            p={6}
            overflow={'hidden'}
        >
            <Stack>
            <Heading
                color='gray.700'
                fontSize={'2xl'}
                fontFamily={'body'}>
                {supertask.name}
            </Heading>
            <Text color={styles.pending.descriptionColor}>
                {(supertask.description || '').padEnd(40, String.fromCharCode(8194)).slice(0, 100)}
            </Text>
            <Accordion>
                {supertask.hydratedSubtasks.map(({ task, taskOccurrence }) => {
                    const style = taskOccurrence?.completedAt ? styles.confirmed : styles.pending
                    return (
                    <AccordionItem key={task.id}>
                        <AccordionButton
                            style={ taskOccurrence?.completedAt ? {
                                backgroundColor: 'green',
                                color: 'white'
                            } : {} }
                        >
                            <Text>{task.name}</Text>
                        </AccordionButton>
                        <AccordionPanel>
                            <Center py={3}>
                            <Box
                                maxW={'445px'}
                                w={'full'}
                                bg={style.bg}
                                boxShadow={'2xl'}
                                rounded={'md'}
                                p={6}
                                overflow={'hidden'}
                            >
                                <Stack>
                                <Heading
                                    color='gray.700'
                                    fontSize={'2xl'}
                                    fontFamily={'body'}>
                                    {task.name}
                                </Heading>
                                <Text color={style.descriptionColor}>
                                    {(task.description || '').padEnd(40, String.fromCharCode(8194)).slice(0, 100)}
                                </Text>
                            </Stack>
                            </Box>
                            </Center>
                        </AccordionPanel>
                    </AccordionItem>
                )})}
                </Accordion>
            </Stack>
        </Box>
        </Center>
    ))

    return (
        <VStack>
            {supertaskCards}
        </VStack>
    )
}