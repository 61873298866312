import { useEffect, useState } from 'react'

import _ from 'lodash'
import {
    Accordion,
    Card,
    CardBody,
    CardHeader,
    Center,
    FormControl,
    FormLabel,
    Heading,
    Switch,
    VStack,
} from '@chakra-ui/react'

import AccordionItemBoilerplate from './helpers/AccordionItemBoilerplate'
import { baseUrl } from '../env'
import dayjs from '../dayjs'

import type { StatsData } from '../types/other'

export default function PunishmentStats() {
    const placeholder = {
        totalPunishments: NaN,
        deliveredPunishments: NaN,
        duePunishments: NaN,
        failedDeliveredPunishments: NaN,
        failedDuePunishments: NaN,
        lateDeliveredPunishments: NaN,
        lateDuePunishments: NaN,
        byTaskPunishments: [],
        lastDelivered: null
    }
    const [stats, setStats] = useState<StatsData.PunishmentStats>(placeholder)
    const [includeDelivered, setIncludeDelivered] = useState(false)

    useEffect(() => {
      const fn = async () => {
        const response = await fetch(`${baseUrl}/punishments/stats`)
        const data = await response.json()
        setStats(data)
      }

      fn()
    }, [])

    const byTaskPunishments = stats.byTaskPunishments.map(byTaskPunishment => ({
        ...byTaskPunishment,
        source: byTaskPunishment.source[0].toUpperCase() + byTaskPunishment.source.slice(1)
    }))
    .filter(byTaskPunishment => includeDelivered || byTaskPunishment.due > 0)
    const [recurringTaskPunishments, oldRecurringTaskPunishments] = _(byTaskPunishments)
    .filter('isRecurringTask')
    .partition('isDeletedTask')
    .reverse()
    .value()

    const [nonRecurringTaskPunishments, standalonePunishments] = _(byTaskPunishments)
    .reject('isRecurringTask')
    .partition('isStandalonePunishment')
    .reverse()
    .value()

    const lastDeliveredF = stats.lastDelivered ? dayjs(stats.lastDelivered).tz('America/Chicago').format('MM/D') : ''

    return Number.isNaN(stats.totalPunishments) ?
        (<Center>{'Weighing heart against feather...'}</Center>) :
    (
        <VStack align='stretch'>
            <FormControl display='flex' alignItems='center'>
                <FormLabel htmlFor='punishments-display' mb='0'>
                    Include delivered punishments
                </FormLabel>
                <Switch
                    id='punishments-display'
                    onChange={e => setIncludeDelivered(e.target.checked)}
                />
            </FormControl>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="lg">Totals</Heading>
                </CardHeader>
                <CardBody>
                    Due: {stats.duePunishments}<br />
                    From late completions: {stats.lateDuePunishments}<br />
                    From missed tasks: {stats.failedDuePunishments}<br />
                    Last delivered: {lastDeliveredF}
                    { includeDelivered ? (
                            <div>
                                Delivered: {stats.deliveredPunishments}<br />
                                From late completions: {stats.lateDeliveredPunishments}<br />
                                From missed tasks: {stats.failedDeliveredPunishments}<br />
                            </div>
                        ): <div><br /><br /> <br /></div>
                    }
                </CardBody>
            </Card>
            <Accordion allowToggle={true}>
                <PunishmentSourceAccordion label='Recurring tasks' byTaskPunishments={recurringTaskPunishments} includeDelivered={includeDelivered} />
                <PunishmentSourceAccordion label='Non-recurring tasks' byTaskPunishments={nonRecurringTaskPunishments} includeDelivered={includeDelivered} />
                <PunishmentSourceAccordion label='Standalone punishments' byTaskPunishments={standalonePunishments} includeDelivered={includeDelivered} />
                <PunishmentSourceAccordion label='Old recurring tasks' byTaskPunishments={oldRecurringTaskPunishments} includeDelivered={includeDelivered} />
            </Accordion>
        </VStack>
    )
}

function PunishmentSourceAccordion({ label, byTaskPunishments, includeDelivered } :
    { label: string, byTaskPunishments: StatsData.ByTaskPunishment[], includeDelivered: boolean }) {
    const makeQuantityText = (byTaskPunishments: StatsData.ByTaskPunishment[]) => {
        const due = _.sumBy(byTaskPunishments, 'due')
        const ever = _.sumBy(byTaskPunishments, 'total')
        const everText = includeDelivered ? `, ${ever} ever` : ''
        return due + ' due' + everText
    }

    return !byTaskPunishments.length ? null : (
        <AccordionItemBoilerplate
            buttonText={`${label}: ${makeQuantityText!(byTaskPunishments)}`}
            panelContent={
                <Accordion allowToggle={true}>
                    {byTaskPunishments.map(byTaskPunishment => <AccordionTaskItem
                        byTaskPunishment={byTaskPunishment}
                        includeDelivered={includeDelivered}
                        key={byTaskPunishment.source}
                    />)}
                </Accordion>
            }
        />
    )
}

function AccordionTaskItem({ byTaskPunishment, includeDelivered }: { byTaskPunishment: StatsData.ByTaskPunishment, includeDelivered: boolean }) {
    const {
        source,
        total,
        delivered,
        due,
        failedDelivered,
        lateDelivered,
        failedDue,
        lateDue
    } = byTaskPunishment
    return (
        <AccordionItemBoilerplate
        buttonText={`${source}: ${due} due` + (includeDelivered ? `, ${total} ever`: '')}
        panelContent={
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="sm">{source}</Heading>
                </CardHeader>
                <CardBody>
                    Due: {due}<br />
                    From late completions: {lateDue}<br />
                    From missed tasks: {failedDue}<br />
                    { includeDelivered ? (
                        <div>
                                Delivered: {delivered}<br />
                                From late completions: {lateDelivered}<br />
                                From missed tasks: {failedDelivered}<br />
                                Total: {total}
                            </div>
                        ): null
                    }
                </CardBody>
            </Card>
        } />
    )
}