import _ from 'lodash'

import {
    MdCheck as CheckIcon,
    MdCheckCircle as CircleCheckIcon,
    MdMoreTime as DelayIcon,
    MdClose as XIcon,
    MdHighlightOff as EmptyCircleXIcon,
    MdCancel as FilledCircleXIcon,
    MdNotInterested as EmptySetIcon,
    MdOutlinePending as PendingIcon
} from 'react-icons/md'
import {
    BsHeartFill as HeartIcon,
    BsFillExclamationCircleFill as ExclamationIcon,
    BsExclamationCircle as EmptyExclamationIcon
} from 'react-icons/bs'

import type { HistoryData } from '../types/other'

const red = 'red.500'
const orange = 'orange.500'
const paleOrange = 'orange.100'
const green = 'green.500'
const blue = 'blue.500'
type Icon = { icon: React.FC, color: string }
const makeFivesAndOnesAdder = (fives: Icon, ones: Icon) => (icons: Icon[], quantity: number = 1) => {
    _.times(Math.floor(quantity / 5), () => icons.push(fives))
    _.times(quantity % 5, () => icons.push(ones))
}
const addRewardIcons = makeFivesAndOnesAdder(
    { icon: HeartIcon, color: green },
    { icon: CircleCheckIcon, color: green }
)
const addGraceIcons = makeFivesAndOnesAdder(
    { icon: HeartIcon, color: blue },
    { icon: CircleCheckIcon, color: blue }
)
const addPunishmentIcons = makeFivesAndOnesAdder(
    { icon: ExclamationIcon, color: red },
    { icon: FilledCircleXIcon, color: red }
)
const addLateCompletionPunishmentIcons = makeFivesAndOnesAdder(
    { icon: ExclamationIcon, color: orange },
    { icon: CircleCheckIcon, color: orange }
)
const addPartialGracePunishmentIcons = makeFivesAndOnesAdder(
    { icon: ExclamationIcon, color: paleOrange },
    { icon: CircleCheckIcon, color: paleOrange }
)
const addDeletedPunishmentIcons = makeFivesAndOnesAdder(
    { icon: EmptyExclamationIcon, color: blue },
    { icon: EmptyCircleXIcon, color: blue }
)

type cs = HistoryData.ConsequenceStates
type IconStateHandler = (icons: Icon[], quantity: number|undefined, consequenceStates?: cs) => void
export const iconAdders = {
    reward: (icons, quantity) => addRewardIcons(icons, quantity),
    punishment: (icons, quantity) => addPunishmentIcons(icons, quantity),
    deleted: (icons) => { icons.push({ icon: EmptySetIcon, color: blue })},
    delayed: (icons) => { icons.push({ icon: DelayIcon, color: blue })},
    completed: (icons, quantity, cs: cs) =>
        cs.activeReward ?
            addRewardIcons(icons, quantity) :
            icons.push({ icon: CheckIcon, color: green }),
    specialGrace: (icons, quantity, cs: cs) =>
        cs.activeReward ?
            addGraceIcons(icons, quantity) :
            icons.push({ icon: CheckIcon, color: blue }),
    completedLate: (icons, quantity, cs: cs) => {
        if (cs.activePunishment) {
            addLateCompletionPunishmentIcons(icons, quantity)
            if (cs.deletedPunishment) {
                // Avoiding referencing the actual number of previous punishments
                // for simplicity's sake, since this is a rare case
                addDeletedPunishmentIcons(icons, 1)
            }
        } else {
            icons.push({ icon: CheckIcon, color: orange })
        }
    },
    failed: (icons, quantity, cs: cs) =>
        cs.activePunishment ? 
            addPunishmentIcons(icons, quantity) :
            icons.push({ icon: XIcon, color: red }),
    mercy: (icons) => { icons.push({ icon: EmptyCircleXIcon, color: blue })},
    retcon: (icons, quantity) => addPartialGracePunishmentIcons(icons, quantity),
    partialGrace: (icons, quantity) => addPartialGracePunishmentIcons(icons, quantity)
} as { [key in HistoryData.ResolutionState]: IconStateHandler }

export const getStyleForIcon = (i: number, iconCount: number) => {
    const style = {} as Record<string, any>
    if (iconCount > 1) {
        if (i < iconCount - 1) {
            style.marginRight = -1
            style.paddingRight = -1
        }
        if (i > 0) {
            style.marginLeft = -5
            style.paddingLeft = -5
        }
    }

    return style
}

export const getOccurrenceHistoryRowIcon = (
    resolutionState: Omit<HistoryData.ResolutionState, 'reward'|'punishment'>,
    hasRewardIncentive: boolean,
    hasPunishmentIncentive: boolean
) => {
    switch (resolutionState) {
        case 'completed':
            return [
                hasRewardIncentive ? CircleCheckIcon : CheckIcon,
                'green.500'
            ]
        case 'specialGrace':
            return [
                hasRewardIncentive ? CircleCheckIcon : CheckIcon,
                'blue.500'
            ]
        case 'failed':
            return [
                hasPunishmentIncentive ? FilledCircleXIcon : XIcon,
                'red.500'
            ]
        case 'completedLate':
            return [
                hasPunishmentIncentive ? CircleCheckIcon : CheckIcon,
                'orange.500'
            ]
        case 'deleted':
            return [
                EmptySetIcon,
                'blue.500'
            ]
        case 'delayed':
            return [
                DelayIcon,
                'blue.500'
            ]
        case 'retcon': // Deliberate fallthrough
        case 'partialGrace':
            return [
                hasPunishmentIncentive ? CircleCheckIcon : CheckIcon,
                'orange.100'
            ]
        case 'mercy':
            return [
                EmptyCircleXIcon,
                'blue.500'
            ]
        case 'pending':
            return [
                PendingIcon,
                'gray.500'
            ]
        default:
            return [
                EmptyExclamationIcon,
                'gray.500'
            ]
    }
}