import React from 'react'

import {
    CardBody,
    Editable,
    EditableInput,
    EditablePreview,
    Input,
} from '@chakra-ui/react'

import dayjs from '../dayjs'

import { StateUser } from '../types/utility'
import { IsoTime } from '../types/branded'

// The multiple-parsing array for dayjs doesn't seem to work,
// so this handles different formats manually
function parseTime(value: string) {
    let cleaned = value.trim().toLowerCase()

    // e.g. 3:00 PM
    if (cleaned.endsWith(' am') || cleaned.endsWith(' pm')) {
        return dayjs(cleaned, 'h:mm a')
    }

    // It ends with a number, e.g. 3:00, or just 3
    if (!Number.isNaN(+cleaned[cleaned.length - 1])) {
        const nowHour = dayjs().hour()
        const textHour = +cleaned.split(':')[0]

        if ((textHour + 12) < nowHour) {
            // e.g. It's 3 PM and the input is "1:00",
            return null
        }
        if (cleaned.length === 1) {
            cleaned += ':00'
        }

        if (nowHour >= 12 || textHour < nowHour) {
            // If it's afternoon now,
            // or it's e.g. 11 AM and the input is "3:00",
            // it's safe to assume it's for the afternoon
            return dayjs(cleaned + ' pm', 'h:mm a')
        }

        // Less certain here, but it's probably supposed to be morning otherwise
        return dayjs(cleaned + ' am', 'h:mm a')
    }

    // The third-to-last character is an number, e.g. 3:00pm
    if (!Number.isNaN(+cleaned[cleaned.length - 3])) {
        return dayjs(cleaned, 'h:mma')
    }

    return null
}

function ReminderFormTime({ notificationTime, setNotificationTime, deadlineTime, setDeadlineTime }: 
    { notificationTime: IsoTime, setNotificationTime: StateUser<IsoTime> , deadlineTime: IsoTime, setDeadlineTime: StateUser<IsoTime> }
) {
    const [notificationInput, setNotificationInput] = React.useState(dayjs(notificationTime).format('h:mm a'))
    const [delayHoursInput, setDelayHoursInput] = React.useState(0)
    const [delayMinutesInput, setDelayMinutesInput] = React.useState(15)

    const [deadlineInput, setDeadlineInput] = React.useState(dayjs(deadlineTime).format('h:mm a'))
    const [deadlineHoursInput, setDeadlineHoursInput] = React.useState(0)
    const [deadlineMinutesInput, setDeadlineMinutesInput] = React.useState(15)

    const notificationHoursFromNow = dayjs(notificationTime).diff(dayjs(), 'hours')
    const notificationMinutesFromNow = dayjs(notificationTime).diff(dayjs(), 'minutes') % 60

    const deadlineHoursFromNotification = dayjs(deadlineTime).diff(dayjs(notificationTime), 'hours')
    const deadlineMinutesFromNotification = dayjs(deadlineTime).diff(dayjs(notificationTime), 'minutes') % 60

    const reconcile = (newNotificationTime: string, newDeadlineTime: string) => {
        let notificationDayjs = dayjs(newNotificationTime)
        let deadlineDayjs = dayjs(newDeadlineTime)

        // The string doesn't include the date,
        // so we need to spell out the fact that it's always in the future
        if (notificationDayjs.isBefore(dayjs())) {
            notificationDayjs = notificationDayjs.add(24, 'hours')
        }

        if (notificationDayjs.isAfter(deadlineDayjs)) {
            deadlineDayjs = notificationDayjs.add(15, 'minutes')
        }

        setNotificationTime(notificationDayjs.toISOString())
        setNotificationInput(notificationDayjs.format('h:mm A'))
        setDelayHoursInput(notificationDayjs.diff(dayjs(), 'hours'))
        setDelayMinutesInput(notificationDayjs.diff(dayjs(), 'minutes') % 60)

        setDeadlineTime(deadlineDayjs.toISOString())
        setDeadlineInput(deadlineDayjs.format('h:mm A'))
        setDeadlineHoursInput(deadlineDayjs.diff(notificationDayjs, 'hours'))
        setDeadlineMinutesInput(deadlineDayjs.diff(notificationDayjs, 'minutes') % 60)
    }

    const handleNotificationTimeChange = (value: string) => {
        const time = parseTime(value)
        if (!time || !time.isValid()) {
            console.log('Invalid time', { time, value })
            setNotificationInput(notificationTimeF)
            return
        }

        reconcile(time.toISOString(), deadlineTime)
    }
    const handleDeadlineTimeChange = (value: string) => {
        const time = parseTime(value)
        if (!time || !time.isValid()) {
            console.log('Invalid time', { time, value })
            setDeadlineInput(deadlineTimeF)
            return
        }

        reconcile(notificationTime, time.toISOString())
    }
    const handleDelayHoursChange = (value: string) => {
        const hours = +value
        if (Number.isNaN(hours)) {
            console.log('Invalid delay hours', { hours, value })
            setDelayHoursInput(notificationHoursFromNow)
            return
        }

        const newTime = dayjs().add(hours, 'hours').add(notificationMinutesFromNow, 'minutes')
        reconcile(newTime.toISOString(), deadlineTime)
    }
    const handleDelayMinutesChange = (value: string) => {
        const minutes = +value
        if (Number.isNaN(minutes)) {
            console.log('Invalid delay minutes', { minutes, value })
            setDelayMinutesInput(notificationMinutesFromNow)
            return
        }

        const newTime = dayjs().add(notificationHoursFromNow, 'hours').add(minutes, 'minutes')
        reconcile(newTime.toISOString(), deadlineTime)
    }
    const handleDeadlineHoursChange = (value: string) => {
        const hours = +value
        if (Number.isNaN(hours)) {
            console.log('Invalid deadline hours', { hours, value })
            setDeadlineHoursInput(deadlineHoursFromNotification)
            return
        }

        const newTime = dayjs(notificationTime).add(hours, 'hours').add(deadlineMinutesFromNotification, 'minutes')
        reconcile(notificationTime, newTime.toISOString())
    }
    const handleDeadlineMinutesChange = (value: string) => {
        const minutes = +value
        if (Number.isNaN(minutes)) {
            console.log('Invalid deadline minutes', { minutes, value })
            setDeadlineMinutesInput(deadlineMinutesFromNotification)
            return
        }

        const newTime = dayjs(notificationTime).add(deadlineHoursFromNotification, 'hours').add(minutes, 'minutes')
        reconcile(notificationTime, newTime.toISOString())
    }

    const handleNotificationTimeInputChange = (value: string) => setNotificationInput(value)
    const handleDelayHoursInputChange = (value: string) => setDelayHoursInput(+value)
    const handleDelayMinutesInputChange = (value: string) => setDelayMinutesInput(+value)

    const handleDeadlineTimeInputChange = (value: string) => setDeadlineInput(value)
    const handleDeadlineHoursInputChange = (value: string) => setDeadlineHoursInput(+value)
    const handleDeadlineMinutesInputChange = (value: string) => setDeadlineMinutesInput(+value)

    const notificationTimeF = dayjs(notificationTime).format('h:mm A')
    const deadlineTimeF = dayjs(deadlineTime).format('h:mm A')

    return <CardBody>
        Reminder time: <Editable value={notificationInput} onChange={handleNotificationTimeInputChange} placeholder={notificationTimeF} onSubmit={handleNotificationTimeChange} display='inline-block' width='auto'>
            <EditablePreview
                style={{
                    fontWeight: 'bold'
                }}
            />
            <EditableInput />
        </Editable> <br />(in <Editable value={delayHoursInput.toString()} onChange={handleDelayHoursInputChange} placeholder={notificationHoursFromNow.toString()} onSubmit={handleDelayHoursChange} display='inline-block' width='auto'>
            <EditablePreview
                style={{
                    fontWeight: 'bold'
                }}
            />
            <Input htmlSize={1} width='auto' as={EditableInput} />
        </Editable> hours, <Editable value={delayMinutesInput.toString()} onChange={handleDelayMinutesInputChange} placeholder={notificationMinutesFromNow.toString()} onSubmit={handleDelayMinutesChange} display='inline-block' width='auto'>
            <EditablePreview
                style={{
                    fontWeight: 'bold'
                }}
            />
            <Input htmlSize={1} width='auto'as={EditableInput} />
        </Editable> minutes)
        <br />
        Deadline: <Editable value={deadlineInput} onChange={handleDeadlineTimeInputChange} placeholder={deadlineTimeF} onSubmit={handleDeadlineTimeChange} display='inline-block' width='auto'>
            <EditablePreview
                style={{
                    fontWeight: 'bold'
                }}
            />
            <EditableInput/>
        </Editable><br />(<Editable value={deadlineHoursInput.toString()} onChange={handleDeadlineHoursInputChange} placeholder={deadlineHoursFromNotification.toString()} onSubmit={handleDeadlineHoursChange} display='inline-block' width='auto'>
            <EditablePreview
                style={{
                    fontWeight: 'bold'
                }}
            />
            <Input htmlSize={1} width='auto' as={EditableInput} />
        </Editable> hours, <Editable value={deadlineMinutesInput.toString()} onChange={handleDeadlineMinutesInputChange} placeholder={deadlineMinutesFromNotification.toString()} onSubmit={handleDeadlineMinutesChange} display='inline-block' width='auto'>
            <EditablePreview
                style={{
                    fontWeight: 'bold'
                }}
            />
            <Input htmlSize={1} width='auto' as={EditableInput} />
        </Editable> minutes after reminder)
    </CardBody>
}

export default ReminderFormTime