import React from 'react'

import {
    Box,
    Button,
    Center,
    CloseButton,
    Flex,
    Heading,
    HStack,
    Icon,
    Input,
    Stack,
    Tag,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'
import getRandomExcuse from '../util/getRandomExcuse'
import { getStyleForIcon, iconAdders } from '../util/historyIcons'
import OccurrenceHistoryRow from './OccurrenceHistoryRow'
import { tagTypes } from './TagFilterBar'
import TaskOccurrenceCardButtons from './TaskOccurrenceCardButtons'

import { IsoDate } from '../types/branded'
import { TaskOccurrenceWithHistory } from '../types/models'
import { StateUser } from '../types/utility'

interface Style {
  dayColor: string,
  bg: string,
  descriptionColor: string
}

const styles = {
  confirmed: {
    dayColor: 'black',
    bg: 'green.500',
    descriptionColor: 'black'
  },
  confirmable: {
    dayColor: 'black',
    bg: 'yellow.500',
    descriptionColor: 'black'
  },
  failed: {
    dayColor: 'black',
    bg: 'red.500',
    descriptionColor: 'black'
  },
  pleading: {
    dayColor: 'black',
    bg: 'red.100',
    descriptionColor: 'black'
  },
  mercy: {
    dayColor: 'black',
    bg: 'blue.200',
    descriptionColor: 'black'
  },
  'completed-late': {
    dayColor: 'black',
    bg: 'orange.500',
    descriptionColor: 'black'
  },
  'partial-grace': {
    dayColor: 'black',
    bg: 'orange.200',
    descriptionColor: 'black'
  },
  retcon: {
    dayColor: 'black',
    bg: 'orange.200',
    descriptionColor: 'black'
  },
  pending: {
    dayColor: 'green.500',
    bg: 'white',
    descriptionColor: 'gray.500'
  },
  fallback: {
    dayColor: 'black',
    bg: 'gray',
    descriptionColor: 'white'
  }
}

const markCompleted = async function(occurrence: TaskOccurrenceWithHistory, updateOccurrences: () => void ) {
    await fetch(`${baseUrl}/tasks/${occurrence.task.id}/occurrences/${occurrence.id}/complete`, {
        method: 'POST'
    })
    updateOccurrences()
}
 
export default function TaskOccurrenceCard({ occurrence, setOccurrences, updateOccurrences }:
  {
    occurrence: TaskOccurrenceWithHistory,
    setOccurrences: StateUser<TaskOccurrenceWithHistory[]>,
    updateOccurrences: () => void
  }) {
    const [isConfirmable, setIsConfirmable] = React.useState(false)
    const [isConfirmed, setIsConfirmed] = React.useState(false)
    const [isPleading, setIsPleading] = React.useState(false)
    const adminView = localStorage.getItem('adminView') === 'true'

    const [justification, setJustification] = React.useState('')

    const { date, deadline, task, resolutionState } = occurrence
    const missed = ['failed', 'completed-late', 'partial-grace', 'mercy', 'retcon'].includes(resolutionState)

    const skipHistoryRow = !adminView && (isConfirmed || isConfirmable || resolutionState !== 'pending')
    let style : Style
    if (isConfirmed) { // confirmed (but page hasn't refreshed yet)
      style = styles.confirmed
    } else if (isConfirmable) { // pending second-tap confirmation
      style = styles.confirmable
    } else if (['failed', 'completed-late'].includes(resolutionState) && occurrence.justification) {
      style = styles.pleading
    } else if (Object.keys(styles).includes(resolutionState)) {
      // @ts-ignore
      style = styles[resolutionState]
    } else {
      style = styles.fallback
    }

    const handleClick = () => {
      if (isConfirmable) {
        setIsConfirmable(false)
        setIsConfirmed(true)
        markCompleted(occurrence, updateOccurrences)
        // This shouldn't do anything visible if the completion succeeded
        setTimeout(() => setIsConfirmed(false), 10000)
      } else {
        setIsConfirmable(true)
        setTimeout(() => setIsConfirmable(false), 2000)
      }
    }

    let deadlineString
    let reminderString
    if (deadline) {
      const deadlineObj = dayjs(deadline)
      // const chicagoTime = deadlineObj.tz('America/Chicago').format('h:mm A z')
      // const localTime = deadlineObj.format('h:mm A z')
      // const isChicagoLocalTime = chicagoTime === localTime
      // if (!isChicagoLocalTime) {
      //   deadlineString = `Due at ${chicagoTime} (${localTime}) `
      // } else {
        deadlineString = `Due at ${deadlineObj.format('h:mm A z')}`
      // }
      deadlineString = deadlineString.replace('11:59 PM', 'midnight')
      deadlineString = deadlineString.replace('12:00 AM', 'midnight')

      if (task.reminderSchedule && !missed) {
        const reminderTimes = task.reminderSchedule.map(
          deadlineModifier => deadlineObj.add(deadlineModifier, 'minute')
          // .tz('America/Chicago')
          .format('h:mm')
        )
        if (reminderTimes.length === 1) {
          reminderString = `Reminder at ${reminderTimes[0]}`
        } else if (reminderTimes.length === 2) {
          reminderString = `Reminders at ${reminderTimes[0]} and ${reminderTimes[1]}`
        } else {
          reminderString = `Reminders at ${reminderTimes.slice(0, -1).join(', ')}, and ${reminderTimes.slice(-1)}`
        }
        // if (!isChicagoLocalTime) {
        //   reminderString += ' CDT'
        // }
      }  
    }
    
    const hideButton = <CloseButton
      display={missed ? 'block' : 'none'}
      onClick={(event) => {
        event.stopPropagation()
        const hiddenOccurrences = JSON.parse(localStorage.getItem('hiddenOccurrences')!) as [IsoDate, string[]][]
        hiddenOccurrences.find(([date]) => date === occurrence.date)![1].push(occurrence.id)
        localStorage.setItem('hiddenOccurrences', JSON.stringify(hiddenOccurrences))
        setOccurrences(occurrences => occurrences.filter(o => o.id !== occurrence.id))
      }}
    />

    const taskNameStems = ['Bedtime', 'Pre-bed', 'Tell Sam about art']
    const name = taskNameStems.find(stem => task.name.startsWith(stem)) || task.name

    const sharedTagStyle = {
      size: 'md',
      variant: 'solid',
      style: { float: 'right', marginTop: '8px' } as React.CSSProperties
    }
    let tag = null
    if (task.isReminder && task.notifyDomOnFailure) {
      tag = <Tag
        {...sharedTagStyle}
        colorScheme='red'
      >
        Notifies Sam
      </Tag>
    } else if (!task.isReminder) {
      const tagName = task.tag || 'Other'
      const colorScheme = tagTypes.find(([name]) => name === tagName)?.[1] || 'gray'
      tag = <Tag
        {...sharedTagStyle}
        colorScheme={colorScheme}
      >
        {tagName}
      </Tag>
    }

    const incentiveIcons = [[], []]
    if (occurrence.history.hasRewardIncentive) {
      iconAdders.reward(incentiveIcons[0], occurrence.history.rewardQuantity!)
    }
    if (occurrence.history.hasPunishmentIncentive) {
      iconAdders.punishment(incentiveIcons[1], occurrence.history.punishmentQuantity!)
    }
    const rewardIcons = incentiveIcons[0].map(({ icon, color }, i, pairs) => (
      <Icon key={icon + i} as={icon} color={color} style={getStyleForIcon(i, pairs.length)} />))
    const punishmentIcons = incentiveIcons[1].map(({ icon, color }, i, pairs) => (
      <Icon key={icon + i} as={icon} color={color} style={getStyleForIcon(i, pairs.length)} />))

    return (
      <Center py={3}>
        <Box
          maxW={'445px'}
          w={'full'}
          bg={style.bg}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}
          overflow={'hidden'}
          onClick={handleClick}
        >
          <Stack>
            <Flex justifyContent='space-between'>
                <Text
                  color={style.dayColor}
                  textTransform={'uppercase'}
                  fontWeight={800}
                  fontSize={'sm'}
                  letterSpacing={1.1}
                  float='left'
                >
                {dayjs(date).format('dddd')}
              </Text>
              {adminView ? <Text>{occurrence.id.slice(0,5)}</Text> : null}
              { hideButton }
            </Flex>
            <Heading
              color={useColorModeValue('gray.700', 'white')}
              fontSize={'2xl'}
              fontFamily={'body'}>
              {(task.isReminder ? 'Reminder: ' : '') + name}
              {tag}
            </Heading>
            <Text color={style.descriptionColor}>
              {task.description}
            </Text>
            <Flex justifyContent='space-between'>
              { deadlineString ? <Text color={style.descriptionColor}>{deadlineString}</Text> : null }
              { missed ? null : <div>{ rewardIcons } { rewardIcons.length && punishmentIcons.length ? '/' : ''} {punishmentIcons}</div>}
            </Flex>
            { reminderString ? <Text color={style.descriptionColor}>{reminderString}</Text> : null }
            { skipHistoryRow ? null : <OccurrenceHistoryRow occurrenceHistory={occurrence.history} />}
            { occurrence.justification ? <Text color={style.descriptionColor}>{'Justification: ' + occurrence.justification}</Text> : null }
            { !adminView && occurrence.justification ? null :
            <TaskOccurrenceCardButtons
              occurrence={occurrence}
              updateOccurrences={updateOccurrences}
              isPleading={isPleading}
              setIsPleading={setIsPleading}
              adminView={adminView}
            />}
            {!isPleading ? null : <HStack>
              <Input
                backgroundColor='white'
                onClick={(event) => event.stopPropagation()}
                placeholder={getRandomExcuse()}
                value={justification}
                onChange={(event) => setJustification(event.target.value)}
              />
              <Button
                onClick={(event) => {
                  event.stopPropagation()
                  setIsPleading(false)
                  fetch(`${baseUrl}/handlers/justification`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                      occurrenceId: occurrence.id,
                      justification
                    })
                  })
                  .then(updateOccurrences)
                }}
                isDisabled={justification === ''}
                backgroundColor='blue.200'
                style={{
                  color: 'black',
                  zIndex: 1000,
                }}
              >
                Submit
              </Button>
            </HStack>}

          </Stack>
        </Box>
      </Center>
    )
  }