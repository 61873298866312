import {
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box
} from '@chakra-ui/react'

export default function AccordionItemBoilerplate({ buttonText, panelContent } : { buttonText: string, panelContent: JSX.Element }) {
    return (
        <AccordionItem>
            <AccordionButton>
                <Box flex='1' textAlign='left'>
                    {buttonText}
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
                {panelContent}
            </AccordionPanel>
        </AccordionItem>
    )
}