import _ from 'lodash'

import {
    Center,
    HStack,
    Stack,
    Tag,
    TagLabel,
    TagRightIcon,
} from '@chakra-ui/react'
import {
    AddIcon,
    CloseIcon,
} from '@chakra-ui/icons'

import { StateUser } from '../types/utility'

export const tagTypes = [
    ['Meds', 'blue'],
    ['Food', 'green'],
    ['School', 'yellow'],
    ['Chores', 'orange'],
    ['Bedtime', 'purple'],
    ['One-off', 'blackAlpha'],
    ['Other', 'gray'],
]
export const tagNames = tagTypes.map(_.first) as string[]

function TagFilterBar({ activeTags, setActiveTags }: { activeTags: string[], setActiveTags: StateUser<string[]>}) {
    const mapTypeToTag = ([tagName, color] : [string, string]) => {
        const isActive = activeTags.includes(tagName)
        const onClick = () => isActive ?
            setActiveTags(activeTags.filter(tag => tag !== tagName)) :
            setActiveTags([...activeTags, tagName])
        return (
            <Tag key={tagName} variant={isActive ? 'solid' : 'outline'} colorScheme={color} onClick={onClick}>
                <TagLabel>{tagName}</TagLabel>
                <TagRightIcon boxSize='10px' as={isActive ? CloseIcon : AddIcon} />
            </Tag>
        )
    }
    return (
        <Center>
            <Stack>
                <HStack spacing={2}>
                    {tagTypes.slice(0, 4).map(tagType => mapTypeToTag(tagType as [string, string]))}
                </HStack>
                <HStack spacing={2}>
                    {tagTypes.slice(4).map(tagType => mapTypeToTag(tagType as [string, string]))}
                </HStack>
            </Stack>
        </Center>
    )
}

export default TagFilterBar