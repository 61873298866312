
import React, { ChangeEvent, useEffect } from 'react'

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormLabel,
    Heading,
    HStack,
    Input,
    Select,
    Switch,
    VStack,
} from '@chakra-ui/react'

import { baseUrl } from '../env'

import type { Task } from '../types/models'

function AdminSuperTaskForm() {
    const [tasks, setTasks] = React.useState([])
    useEffect(() => {
        async function fetchTasks() {
            const res = await fetch(`${baseUrl}/tasks`)
            const data = await res.json()
            setTasks(data)
        }
        fetchTasks()
    }, [])

    const [taskTitle, setTaskTitle] = React.useState('')
    const [taskDescription, setTaskDescription] = React.useState('')
    const [isRecurring, setIsRecurring] = React.useState(false)
    const handleTaskTitleChange = (event: ChangeEvent<HTMLInputElement>) => setTaskTitle(event.currentTarget.value)
    const handleTaskDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => setTaskDescription(event.currentTarget.value)

    const [isLoading, setIsLoading] = React.useState(false)
    const [didError, setDidError] = React.useState(false)
    const [didSucceed, setDidSucceed] = React.useState(false)

    const handleButtonPress = async () => {
        setIsLoading(true)
        const response = await fetch(`${baseUrl}/supertasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: taskTitle,
                description: taskDescription,
                isRecurring,
                subtasks: [1, 2, 3, 4, 5].reduce((acc: string[], n) => {
                    const select = document.getElementById(`task${n}`) as HTMLSelectElement
                    const value = select.value
                    if (value) {
                        return [...acc, value]
                    }
                    return acc
                }, [])
            })
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            setTaskTitle('')
            setTaskDescription('')
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
        <VStack align='stretch'>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="md">Create supertask</Heading>
                </CardHeader>
                <CardBody>
                    Title: <Input value={taskTitle} onChange={handleTaskTitleChange} size='md' />
                    Description: <Input value={taskDescription} onChange={handleTaskDescriptionChange} size='md' />
                    <br /><br />
                    <HStack>
                        <FormLabel htmlFor='recurring' mb='0'>
                            Is recurring
                        </FormLabel>
                        <Switch
                            id='recurring'
                            onChange={e => setIsRecurring(e.target.checked)}
                            isChecked={isRecurring}
                        />
                    </HStack>
                <div /><br />
                {[1, 2, 3, 4, 5].map(n =>
                    <div>
                    Task {n}:
                    <Select id={`task${n}`}>
                        <option value=''>Select a task</option>
                        {tasks.map((task: Task) => (
                            <option key={task.id} value={task.id}>{task.name}</option>
                        ))}
                    </Select>
                    <br />
                    </div>
                )}
                </CardBody>
            </Card>
            <Button
                isDisabled={!taskTitle.length}
                isLoading={isLoading}
                colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
                onClick={handleButtonPress}
            >
                {didSucceed ? 'Created supertask!' : didError ? 'Failed to create supertask' : 'Create supertask' }
            </Button>
        </VStack>
    )
}

export default AdminSuperTaskForm